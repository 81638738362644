import { React } from 'react';

function Footer() {
    return(
        <div className="footer">
        <div className="">
            <div className="row justify-content-center">
                <div className="col-12 col-md-2 offset-2 offset-md-1 offset-sm-2">
                    <h4>Offerings</h4>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <a href="/offerings/consulting" className="footer-links">
                                Consulting
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="/offerings/examination-as-a-service" className="footer-links">
                                Examination as a Service
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="/offerings/it-as-a-service" className="footer-links">
                                IT as a Service
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <div className="col-12 col-md-2 offset-2 offset-md-1 offset-sm-2 d-none d-sm-block d-md-none">   
                    <h4><a className="footer-links" href="/contact">About Us</a></h4>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                History
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                Mission
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                Vision
                            </a>
                        </li>
                    </ul>
                </div> */}
                <div className="col-12 col-md-2 offset-2 offset-md-1 offset-sm-2">
                    <h4>Careers</h4>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <a href="/careers" className="footer-links">
                                View Opportunities
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-2 offset-2 offset-md-1 offset-sm-2">   
                    <h4><a className="footer-links" href="/contact">Contact Us</a></h4>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <a href="/contact#offices" className="footer-links">
                                Locations
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <div className="col-12 col-md-2 offset-2 offset-md-1 offset-sm-2 d-sm-none d-md-block">   
                    <h4><a className="footer-links" href="/contact">About Us</a></h4>
                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                History
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                Mission
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="#" className="footer-links">
                                Vision
                            </a>
                        </li>
                    </ul>
                </div> */}
                {/* <div className="col-12 col-sm-3">
                    <h4><a className="footer-links" href="/about">Resources</a></h4>
                    <hr className="footer-hr"/>
                    <ul className="list-unstyled"> 
                        <li className="mb-3">
                            <a href="/casestudy1" className="footer-links">
                            Cloud Based Examination in over 40 Countries
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="/casestudy2" className="footer-links">
                                Zero Day Project During Christmas
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="/casestudy3" className="footer-links">
                            Item Banking for 38 Languages and 234 Subjects
                            </a>
                        </li>
                    </ul>
                </div> */}
            </div>
            <hr style={{color:"white"}}/>
            <div className="row">
                <div className="col-md-12">
                    <p style={{color:"white", fontSize : '15px', textAlign: 'center'}}>© Copyright 2024, CQTech</p>
                </div>
                </div>
        </div>
    </div>
    );
}

export default Footer;