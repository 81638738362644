import { NavDropdown, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../../Header.css';

const WebNav = ({ scrolled }) => {
  const location = useLocation();

  return (
    <Nav className="me-auto pull-right">
      <Link 
        className={`nav-link ${location.pathname === '/home' ? scrolled ? 'active-bold'  : 'active' : ''}`} 
        to='/home' 
        style={{ color: scrolled ? '' : '#d2c0c0' }}
      >
        Home
      </Link>
      <NavDropdown 
        title='Offerings' 
        id="basic-nav-dropdown" 
        className={`navDropDown-toggle-sub ${scrolled ? 'no-color' : ''} ${location.pathname === '/offerings/it-as-a-service' || location.pathname === '/offerings/examination-as-a-service' || location.pathname === '/offerings/consulting' ? 'active-dropdown' : ''}`}
      >
        <Link 
          to={{ 
            pathname: "/offerings/examination-as-a-service", 
            state: { page: 1 } 
          }} 
          className={`nav-link dropdown-menuitems`}
        >
          Examination as a Service
        </Link>
        <NavDropdown.Divider />
        <Link 
          to={{ 
            pathname: "/offerings/consulting", 
            state: { page: 2 } 
          }} 
          className={`nav-link dropdown-menuitems`}
        >
          Consulting
        </Link>
        <NavDropdown.Divider />
        <Link 
          to={{ 
            pathname: "/offerings/it-as-a-service", 
            state: { page: 3 } 
          }} 
          className={`nav-link dropdown-menuitems`} 
        >
          IT as a Service
        </Link>
      </NavDropdown>
      <Link 
        to="/careers" 
        className={`nav-link ${location.pathname === '/careers' ? scrolled ? 'active-bold'  :  'active' : ''}`} 
        style={{ color: scrolled ? '' : '#d2c0c0' }}
      >
        Careers
      </Link>
      <Link 
        to="/contact" 
        className={`nav-link ${location.pathname === '/contact' ? scrolled ? 'active-bold'  :  'active' : ''}`} 
        style={{ color: scrolled ? '' : '#d2c0c0' }}
      >
        Contact
      </Link>
    </Nav>
  );
};

export default WebNav;