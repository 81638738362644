import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../../Header.css';

const MobileNav = ({ scrolled }) => {
    const location = useLocation();
    return (
        <>
            <NavDropdown style={{ left: 'auto', right: 0 }} title={<span className="fa fa-solid fa-bars" style={{ color: scrolled ? "#092a49" : "white" }}></span>} flip={false} id="nav-dropdown">
                <Link className={`dropdown-item ${location.pathname === '/home' ? 'active-nav' : ''}`} to='/home'>Home</Link>
                <NavDropdown.Divider />
                <NavDropdown title="Offerings" id={`mobile-dropdown-menu`} className={`${location.pathname === '/offerings/examination-as-a-service' || location.pathname === '/offerings/consulting' || location.pathname === '/offerings/it-as-a-service' ? 'active-dropdown' : 'font-weight-400'}`}>
                    <Link
                        className={`dropdown-item`}
                        to={{ pathname: "/offerings/examination-as-a-service", state: { page: 1 } }}
                    >
                        Examination as a Service
                    </Link>
                    <NavDropdown.Divider />
                    <Link
                        className={`dropdown-item`}
                        to={{ pathname: "/offerings/consulting", state: { page: 2 } }}
                    >
                        Consulting
                    </Link>
                    <NavDropdown.Divider />
                    <Link
                        className={`dropdown-item`}
                        to={{ pathname: "/offerings/it-as-a-service", state: { page: 3 } }}
                    >
                        IT as a Service
                    </Link>
                </NavDropdown>
                <NavDropdown.Divider />
                <Link className={`dropdown-item ${location.pathname === '/careers' ? 'active-nav' : ''}`} to='/careers'>Careers</Link>
                <NavDropdown.Divider />
                <Link className={`dropdown-item ${location.pathname === '/contact' ? 'active-nav' : ''}`} to='/contact'>Contact</Link>
            </NavDropdown>
        </>
    )
}

export default MobileNav;