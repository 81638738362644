import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HomeHeader from '../Header/HomeHeaderComponent';
import CommonSection from '../CommonSection';

const Examination = () => {
    return (
        <>
        <HomeHeader />
        <CommonSection />
        <Container className="ml-0 mr-0" style={{maxWidth: "inherit", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="service-container" style={{ padding: '28px 8px 0 0' }}>
                <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>Examination Services</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%"  }}>Clients can leverage any part of our Examination Services spectrum as a whole, and yet only pay for utilized services and not for the entire cost of the solution.
                    Plus, with around the clock support and customer first thinking, we build relationships with you that enable us to grow together.</p><br/>
                </Col>
                <Col sm={12} md={6} className="p-0">
                    <img src="../assets/images/Item Banking Illustration.svg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
            {/* <Row className="services-cards ml-5 mr-5"> */}
            {/* <Col xs={12} md={4} lg={3}>
                    Item Banking
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                            <img height="120" width="120" src='../assets/images/Item Banking Icon 1-1-01.svg' 
                            alt="Item Banking" className="ml-2"/>   
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'> Item <br/> Banking </Typography>
                                <Typography variant='body2' color='text.secondary' className="mr-1">
                                    <p>Offline and online process ensuring the exams conducted are error free.</p>
                                </Typography>
                            </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Question Paper Printing
                    <Card className="text-center solutions-card">
                        <img src="../assets/images/Paper and Pencil Exam Icon 1-1-01.svg" alt="Question Paper Printing"
                        height="120" width="120" className="ml-2"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component="div">
                                Question Paper Printing
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                            <p>Complete online mananagement system enables the power of digitization of exams.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    OMR Solutions
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/OMR Scanning Icon 1-1-01.svg'  className="ml-2"
                        alt="OMR Solutions"  height="120" width="120"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> OMR<br/> Solutions </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Process OMR sheet within a second with AI powered OMR processing software.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Certificate
                        <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                            <img src='../assets/images/Security Printing Icon 1-1-01.svg'  className="ml-2"
                            alt="Certificate" height="120" width="120"/>
                            <CardContent className="text-center">
                                <Typography gutterBottom variant='h5' component='div'> Certificate<br/> Printing</Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    <p>Capabale of generating the physical as well the digital certificate. </p>
                                </Typography>
                            </CardContent>
                        </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    eAssessment
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/Proctoring Icon 1-1-01.svg' className="ml-2"
                        alt="eAssessment" height="120" width="120"/>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Online <br/> Examination </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>        
                <Col xs={12} md={4} lg={3}>
                    <Card className="text-center solutions-card mt-0" sx={{ maxWidth: 345 }}>
                        <a href="/examination">
                        <img src='../assets/images/Arrow 1-1 - Color.svg' className="ml-2"
                        alt="Right Arrow" height="120" width="120"/>
                        </a>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Learn More </Typography>
                        </CardContent>
                    </Card>
                </Col> */}
            {/* </Row> */}
        </Container>
        <div className="container ml-0 mr-0 pl-0 pr-0 mt-5" style={{maxWidth: "fit-content"}}>
            <div className="row row-content justify-content-center mb-5" id="item_banking" style={{maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3">
                    <span className="offerings-para"><img src='../assets/images/Item Banking Icon 1-1-01.svg' className="mr-3" alt="Examination Process Advisory" height="100px"/>Item Banking</span>
                    <p>Designed to be both integrated and modular, AI powered tools like <b>Item Generation</b> and <b>Translation</b> are built right in, batteries included. Cost efficient and resource-optimized, without making compromises. The system slashes administrative effort and increases the quality of assessments through tools like <b>Item Enemy/Duplication Detection</b> and <b>Automated Test/Form Assembly</b>. </p>
                    <p>Our system preserves content through <b>Recoverable Versioning</b> as it progresses within <b>Dynamic Worlflows</b>. And for maintaining the Item Bank's defensibility and security, <b>Audit Trails</b> and <b>Role-Based Access Control</b> are constantly working in the background. </p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <video className="ibank-video" controls autoPlay preload="auto" muted loop>   
                        <source src="../assets/images/item-banking.mp4"
                        type="video/mp4"/>
                        Sorry, your browser doesn't support videos.
                    </video>
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 ">
                    <span className="offerings-para"><img src='../assets/images/Paper and Pencil Exam Icon 1-1-01.svg' className="mr-3 float-left mt-1" alt="Question Papers" height="80px"/>
                    <span className='align-self-center' style={{verticalAlign: 'middle'}}> Question Papers</span>
                    </span>
                    <p>
                    Academic, psychometric, and personnel testing have been dominated by the paper-and-pencil test for a long time. For high volume, simultaneous examination, paper and pencil continues to be the preferred medium even with the rise of computer based testing.
                    </p>
                    <ul>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                        Unparalleled Security: Our industry leading approach utilises many layers of security from the paper to the outermost packaging, to the supply chain security.
                            {/* <ul>
                                <li style={{color: "black", fontSize: "1.125rem"}}>GPS locks</li>
                                <li style={{color: "black", fontSize: "1.125rem"}}>One-time locking seals</li>
                            </ul> */}
                        </li>
                        <br/>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                        Unbounded Capacity: We develop lean solutions for Question Paper projects, giving them the legs to scale to millions of papers printed per day.                        </li>
                        <br/>
                        {/* <li style={{color: "black", fontSize: "1.125rem"}}>
                        Enduring Reliability: Around the clock support and customer first thinking. For us, that means building relationships that allow us to grow together.
                        </li> */}
                    </ul>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img src="../assets/images/Paper and Pencil Exam Illustration.svg" alt="Question Papers" width="100%" height="100%" style={{objectFit: "contain"}}/> 
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5" style={{maxWidth: "fit-content", marginRight: "0px", marginLeft: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3">
                    <span className="offerings-para">
                        <img src='../assets/images/OMR Scanning Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="OMR Solutions" height="80px"/>
                        <span className='align-self-center' style={{verticalAlign: 'middle'}}> OMR Solutions</span></span>

                    <p>Our Answer Sheet Solution covers the entire process from scanning to processing the results. It offers a fast, hardware independent, scalable solution for scanning and processing a high volume of answer sheets. There are two techniques that we utilize: OMR Scanning and Image Scanning.</p>
                    <p>OMR Scanning utilizes purpose-built scanners which have reader heads that sense the presence of filled bubbles while Image Scanning uses multipurpose, low-cost scanners to obtain images of the answer sheets which are processed by our software to produce the results.</p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <img src="../assets/images/OMR Scanning Illustration.svg" alt="OMR Solutions" width="100%" height="90%" style={{objectFit: "contain"}}/>
                </div>
            </div>
            {/* <div className="row row-content justify-content-center" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-md-4 mt-5 mb-5">
                    <span className="offerings-para"><img src='../assets/images/Security Printing Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="Certificate Printing" height="15%" width="15%"/>Security Printing</span>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div className="col-12 col-md-4 mr-3">
                    <img src="../assets/images/Security Printing 1.jpg" alt="Certificate Printing" width="100%" height="75%" style={{objectFit: "contain"}}/>
                </div>
            </div> */}
            <div className="row row-content justify-content-center pt-5 pb-5" style={{maxWidth: "fit-content", marginRight: "0px", marginLeft: "0px",backgroundColor: "#F8F8F8", marginTop : '20px', paddingTop: '25px'}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4">
                <span className="offerings-para">
                    <img src='../assets/images/Proctoring Icon 1-1-01.svg' className="mr-3 mt-1 float-left" alt="eAssessment" height="100px"/>
                <span className='align-self-center' style={{verticalAlign: 'middle'}}> Cloud Examination</span>
                </span>
                    <p>
                    To handle dynamic and rapidly changing examination environments, the online medium provides unmatched flexibility. Ad-hoc scheduling allows administrators to plan, conduct, and get results within hours, a process which traditionally takes weeks.
                    </p>
                    <ul>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Planning: Exam events can be created, and students can be grouped, in one seamless flow.
                        </li>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Conducting: Exams are automatically deployed to the server and the dashboard provides insight about each candidate’s progress and status.
                        </li>
                        <li style={{color: "black", fontSize: "1.125rem"}}>
                            Results: The results for all students are reported automatically at the end of a session.
                        </li>
                    </ul>
                    <p>
                    In addition, we supply a Proctor module which allows administrators to monitor each candidate that takes the exam. Audio, video, or periodic photos can be captured from the same device that the assessment is taking place from.
                    </p>
                </div>
                <div className="col-12 col-sm-10 col-md-10 col-lg-4 align-self-center">
                    <img src="../assets/images/Proctored Online Exam Illustration.svg" alt="eAssessment" width="100%" height="67%" style={{objectFit: "contain"}}/>
                </div>
            </div>
        </div>
        </>
    );
}

export default Examination;