import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HomeHeader from '../Header/HomeHeaderComponent';
import CommonSection from '../CommonSection';

const ItServices = () => {
    return (
        <>
        <HomeHeader />
        <CommonSection />
        <Container className="ml-0 mr-0" style={{maxWidth: "fit-content", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="product-container it-service" style={{padding:'66px 77px 59px 0px'}}>
            <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>IT Services</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%" }} className="paraText">Our IT Services offer clients the power of choice with a focus on innovation, agility, and aligning resources to minimize infrastructure investments and capital expenditure.</p><br/>
                </Col>
            <Col sm={12} md={6} className="p-0">
                    <img src="../assets/images/Online Exam Illustration 2.svg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
        </Container>
        
        <div className="container ml-0 mr-0 pl-0 pr-0" style={{maxWidth: "fit-content"}}>
        <div className="row row-content justify-content-center pt-5 pb-5" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4">
                    <span className="offerings-para"><img src="../assets/images/Biometric Verification Icon 1-1-01.svg" height="80px" alt="Biometrics" className="mr-3 mt-1 float-left"/>
                    <span className='align-self-center' style={{verticalAlign: 'middle'}}> Biometrics</span>
                    </span>
                    <p>
                    We use proprietary Biometric Verification software to verify process stakeholders and maintain unique identification by sensing a person’s face, fingerprint, and retina.                     </p>
                    <p>
                    Cloud technology is leveraged to make Biometric Verification more accessible and resilient. Cloud-based systems enable agencies and organizations to perform biometric identification in a secure way.  
                    </p>
                    {/* <p>
                    Cloud technology is often used to make biometric information more accessible and portable. Cloud-based systems enable agencies and organizations to perform biometric identification on any individual, regardless of their location.
                    </p> */}
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img width="100%" height="70%" src="../assets/images/Biometric Verification Illustration.svg" alt="Biometrics" style={{objectFit: "contain", marginTop: "3%"}}/>
                </div>
            </div>
            <div className="row row-content justify-content-center pt-5 pb-5">
                <div className="col-10 col-sm-10 col-md-10 col-lg-4"> 
                <span className="offerings-para"><img src="../assets/images/Customized Software Icon 1-1-01.svg" height="100px" alt="Customized Software" className="mr-3 float-left"/>
                <span className='align-self-center' style={{verticalAlign: 'middle'}}> Customized Software</span>
                </span>
                    <p className="justify-content-left">
                    Working in concert with our service design consulting approach, our talented engineering team tackles the toughest software challenges. From real-time high performance computing to integrating human centered Artificial Intelligence, to building systems resilient to minimal if not absent internet connectivity.
                    </p>
                </div>
                <div className="col-10 col-sm-10 col-md-10 col-lg-4 mr-3 align-self-center">
                    <img src="../assets/images/Customized Software Diagram Illustration.svg" alt="Customized Software"
                    height="100%" width="100%" style={{objectFit: "contain"}}/>
                </div>
            </div>
            {/* <div className="row row-content justify-content-center" style={{backgroundColor: "#F8F8F8", maxWidth: "fit-content", marginLeft: "0px", marginRight: "0px"}}>
                <div className="col-10 col-md-4 mt-5 mb-5"> 
                    <span className="offerings-para"><img src="../assets/images/Online Exam Icon 1-1-01.svg" height="15%" width="15%" alt="Customized Software" className="mr-3 mt-3 float-left"/>
                    Infrastructure Management</span>
                    <p className="justify-content-left">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div className="col-10 col-md-4">
                    <img src="../assets/images/carousel-21.JPG" alt="Infrastructure Management" height="95%" width="100%" style={{objectFit: "contain"}}/>
                </div>
            </div> */}
        </div>
        </>
    );
}

export default ItServices;