import React, { Component } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import MobileNav from './Components/mobileNav';
import WebNav from './Components/WebNav';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};


class HomeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavOpen: false,
      isModalOpen: false,
      scrolled: false
    };

    this.toggleNav = this.toggleNav.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // Check if user has scrolled down
    const scrolled = window.scrollY > 0;
    this.setState({ scrolled });
  }
  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  }
  render() {
    const { scrolled } = this.state; return (
      <>
        <HideOnScroll >
          <Navbar collapseOnSelect expand="md" className={scrolled ? 'navbar scrolled' : 'navbar'} >
            <Container fluid className="ml-3 mr-3">
              <Link to="/home" className="navbar-brand">
                <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo" />
              </Link>

              <Navbar.Toggle expanded={false} aria-controls="basic-navbar-nav" style={{ color: "white" }}>
                {/* <span className="fa fa-solid fa-bars" style={{color:scrolled? "#092a49": "white"}}></span> */}
                {/* <NavDropdown style={{ left: 'auto', right: 0 }} title={<span className="fa fa-solid fa-bars" style={{ color: scrolled ? "#092a49" : "white" }}></span>} flip={false} id="nav-dropdown">
                  <Link className="dropdown-item" eventKey="1" to='/home'>Home</Link>
                  <NavDropdown.Divider />
                  <NavDropdown title="Offerings" id="mobile-dropdown-menu">
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/examination-as-a-service",
                    state: { page: 1 }
                }} >Examination as a Service</Link>
            <NavDropdown.Divider />
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/consulting",
                    state: { page: 2 }
                }} >Consulting</Link>
            <NavDropdown.Divider />
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/it-as-a-service",
                    state: { page: 3 }
                }}>IT as a Service</Link>
        </NavDropdown>
                  <NavDropdown.Divider />
                  <Link className="dropdown-item" eventKey="3" to='/careers'>Careers</Link>
                  <NavDropdown.Divider />
                  <Link className="dropdown-item" eventKey="4" href='/contact'>Contact</Link>
                </NavDropdown> */}
                <MobileNav scrolled={scrolled} />
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav" style={{ display: 'flex', right: '50px', position: 'absolute' }} className='d-none d-sm-none d-md-none'>
              <WebNav scrolled={scrolled} />
              </Navbar.Collapse>
              {/* <Navbar.Collapse id="basic-navbar-nav"> */}
            </Container>
          </Navbar>
        </HideOnScroll>
      </>

    );
  }
}

export default HomeHeader;