import React from 'react';
import { Container, Row } from 'react-bootstrap';


const CommonSection = () => {
    return (
        <>
         <Container fluid className="p-0">
            <Row className="p-0 m-0">
                <div className="pages-div">
                    <img src="../assets/images/CQ Tech Website BG Design Option 2.png" alt="Our Company" width="100%"/>
                </div>
                <p className="p-0 m-0">
                    <p className="page-heading">How We Help Others</p>
                </p>
            </Row>
        </Container>
        </>
    )
}

export default CommonSection;