import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HomeHeader from '../Header/HomeHeaderComponent';
import CommonSection from '../CommonSection';

const Consulting = () => {
    return (
        <>
          <HomeHeader />
                <CommonSection />
        <Container className="ml-0 mr-0" style={{maxWidth: "fit-content", 
        paddingLeft: '0', paddingRight: '0'}}>
            <Row className="product-container" style={{padding: '43px 0px 59px 0px'}}>
            <Col sm={12} md={6} className="p-0 d-sm-none d-md-block">
                    <img src="../assets/images/Consulting Illustration.svg" alt="Products" width="100%" height="100%"/>
                </Col>
            <Col sm={12} md={6} className='text-center' style={{ marginTop: "10%" }}>
                    <h1 style={{ color: "white", marginBottom: "3%" }}>Consulting</h1>
                    <p style={{ color: "white", paddingLeft: "7%", paddingRight: "7%" }}>After years of working with governments around the world, we converged on a consulting process which encourages innovative solutions and remains cognizant of subject matter expertise.</p><br/>
                </Col>
                <Col sm={12} md={6} className="p-0 d-none d-sm-block d-md-none">
                    <img src="../assets/images/Consulting Illustration.svg" alt="Products" width="100%" height="100%"/>
                </Col>
            </Row>
        </Container>
            {/* <Row className="services-cards">
            <Col xs={12} md={4} lg={3} >
                    Examination Process Advisory
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/examad.png' className="mb-3"
                        alt="Examination Process Advisory" height="90" width="90"/>
                        <CardContent className="text-center">
                            <Typography gutterBottom variant='h5' component='div'> Examination Process Advisory </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Software and IoT Technology
                    <Card className="text-center solutions-card">
                        <img src='../assets/images/Proctoring Icon 1-1-01.svg'
                        alt="Certificate" height="110" width="110"/>
                        <CardContent className="text-center">
                        <Typography gutterBottom variant='h5' component='div'> Software and IoT Technology</Typography>
                        <Typography variant='body2' color='text.secondary'>
                            <p>Capabale of generating the physical as well the digital certificate. </p>
                        </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    Digital Archiving and Content Management
                    <Card className="text-center solutions-card" sx={{ maxWidth: 345 }}>
                        <img src='../assets/images/da-and-cm.png' className="mb-3"
                        alt="Certificate" height="90" width="90"/>
                        <CardContent className="text-center">
                        <Typography gutterBottom variant='h5' component='div'> Digital Archiving and Content Management</Typography>
                        <Typography variant='body2' color='text.secondary'>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Typography>
                        </CardContent>
                    </Card>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    <Card className="text-center solutions-card m-0" sx={{ maxWidth: 345 }}>
                        <a href="/examination">
                        <img src='../assets/images/Arrow 1-1 - Color.svg' className="ml-2"
                        alt="Right Arrow" height="120" width="120"/>
                        </a>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'> Learn More </Typography>
                        </CardContent>
                    </Card>
                </Col>
            </Row> */}
        <div className="container">
            <div className="row row-content justify-content-center mt-5">
                <div className="col-10 col-md-7">
                    <h1 style={{color: "#092a49"}}>Service Design Overview</h1>
                    <p>When one of the most prestigious Examinations Councils in Africa asked us to help them undergo a digital transformation of their Item Banking process, it had a simple brief: Ensure a great customer experience for exam administrators by digitizing manual processes.</p> 
                    <p>As we began to examine the pieces that made up customer experience — specifically, how exam admins created content (items) — we discovered it wasn’t so simple to build a great customer experience. Beyond creating exam content, there were many factors at play in the successes and failures of the Council’s technology migration, including how roles were structured, work processes, communication channels, feedback loops, and more.</p>
                    {/* <p>
                    Enter service design.
                    </p> */}
                </div>
            </div>
            <div className="row row-content justify-content-center">
                <div className="col-10 col-md-7">
                    <div>
                    <h2 style={{color: "#092a49"}}>What service design is</h2>
                    <p className="mb-5">Service design enables us to create sustainable and high-quality experiences for both customers and the beneficiaries of the services they provide. Using a set of principles, activities, and methods, the discipline takes a holistic look across a service ecosystem. This includes the “frontstage” (what a customer sees) and the “backstage” (behind-the-scenes organizational structures).</p>
                    <img src="../assets/images/Behind the Curtains Illustration 1-1.png" alt="Consulting" width="100%" height="60%" style={{objectFit: "contain"}}/>
                    <p className="mt-5">
                    While many organizations focus on improvements to customer experience — which was the case with council’s emphasis on digitizing manual processes — frontstage-only solutions often fail without consideration for the systems that support them.
                    </p>
                    <p>
                    For the council and government organizations of all types, service design provides the tools to understand and improve the whole system. In the Council’s case, this meant looking at the technologies, staff within the office, users that interact with exam data outside the organization, and how they all work together. It meant bringing people along in the process to identify problems, come up with the right solutions, and put them into action.
                    </p>
                    </div>
                </div>
            </div>
            <div className="row row-content justify-content-center">
                <div className="col-10 col-md-7">
                    <h2 style={{color: "#092a49"}}>When to use service design</h2>
                    <p>As was the case for the Council, many projects don’t begin with a plan to use service design. Once you dig into a problem, though, the complexities start to emerge and the need for service design becomes clear.</p>
                    <p>Recognizing from the outset what kinds of problems require service design isn’t always easy, but there are patterns to look out for. According to a Deloitte study of government service design projects, each of these problems can present a good opportunity for service design:</p>
                    <ul>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Lack of knowledge about the customers you serve</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Siloed communication, processes, and systems within an organization</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Customers with unique and specific needs</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Outdated, fragmented, and insufficient technology systems</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Poor customer service</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Complicated legal and political landscape</p></li>
                    </ul>
                    <p>
                    Governments bodies deliver many different layered services, from legislation and policy to digital products. No matter the exact problem we’re trying to solve, this kind of complex landscape is a great fit for a service design approach.
                    </p>
                </div>
            </div>
            <div className="row row-content justify-content-center pb-5">
                <div className="col-10 col-md-7">
                    <h2 style={{color: "#092a49"}}>Who uses service design</h2>
                    <p>
                    In service design projects, we usually involve a small team of service designers in partnership with a core government team that owns or helps manage a service. Projects also include key stakeholders, such as organization leadership, staff who administer or interact with a service, and customers of a service. Depending on the nature of a project, teams may include an interdisciplinary mix of roles such as engineers, visual designers, and customer support staff.
                    </p>
                    <p>
                    While the makeup of our service design teams can vary, there are some essential capacities needed for a successful project, including:
                    </p>
                    <ul>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Decision-making skills</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Scoping and understanding what’s viable</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Championing and teaching the process</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Allowing voices and ideas to be heard</p></li>
                        <li style={{color: "black"}}><p style={{padding: 0,margin: 0}}>Knowledge of methods and when to use them</p></li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}

export default Consulting;