import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Home from './HomeComponent';
import Careers from './CareersComponent';
import Contact from './ContactComponent';
import CaseStudy1 from './CaseStudy1Component';
import CaseStudy2 from './CaseStudy2Component';
import CaseStudy3 from './CaseStudy3Component';
import { fetchJobs } from '../redux/ActionCreators';
import Footer from './FooterComponent';
import Mission from './MissionComponent';
import Vision from './VisionComponent';
import History from './HistoryComponent';
import Examination from './Offerings/Examination';
import Consulting from './Offerings/Consulting';
import ItServices from './Offerings/ItService';

const Main = () => {
    const [currentId, setCurrentId] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchJobs());
      }, [currentId, dispatch]);

    return (
                <div className="App">
                <Switch>
                    <Route exact path="/home" component={() => <Home />} />
                    {/* <Route path="/about" component={() => <About />} /> */}
                    <Route path="/contact" component={() => <Contact />} />
                    <Route path="/offerings/examination-as-a-service" component={() => <Examination />} />
                    <Route path="/offerings/consulting" component={() => <Consulting />} />
                    <Route path="/offerings/it-as-a-service" component={() => <ItServices />} />
                    <Route path="/careers" component={() => <Careers 
                                                            currentId={currentId}
                                                            setCurrentId={setCurrentId}/>} />
                    <Route path="/casestudy1" component={() => <CaseStudy1 />} />
                    <Route path="/casestudy2" component={() => <CaseStudy2 />} />
                    <Route path="/casestudy3" component={() => <CaseStudy3 />} />
                    <Route path="/history" component={() => <History />} />
                    <Route path="/vision" component={() => <Vision />} />
                    <Route path="/mission" component={() => <Mission />} />
                    <Redirect to="/home" />
                </Switch>
                <Footer />
            </div>
    );
}

export default Main;