import React, { Component } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import MobileNav from './Components/mobileNav';
import WebNav from './Components/WebNav';

class PagesHeader extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isNavOpen: false,
            scrolled: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        // Check if user has scrolled down
        const scrolled = window.scrollY > 0;
        this.setState({ scrolled });
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render() {
        const { scrolled } = this.state;
        return (
            <>
                <Navbar collapseOnSelect className={scrolled ? "pages-navbar scrolled" : "pages-navbar"} expand="md">
                    <Container fluid className="ml-3 mr-3">
                        <Link to="/home" className="navbar-brand">
                            <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo"/>    
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color: "white"}}>
         {/* <NavDropdown style={{ left: 'auto', right: 0 }} title={<span className="fa fa-solid fa-bars" style={{ color: scrolled ? "#092a49" : "white" }}></span>} flip={false} id="nav-dropdown">
                  <Link className="dropdown-item" eventKey="1" to='/home'>Home</Link>
                  <NavDropdown.Divider />
                  <NavDropdown title="Offerings" id="mobile-dropdown-menu">
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/examination-as-a-service",
                    state: { page: 1 }
                }} >Examination as a Service</Link>
            <NavDropdown.Divider />
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/consulting",
                    state: { page: 2 }
                }} >Consulting</Link>
            <NavDropdown.Divider />
            <Link className="dropdown-item" to={{
                    pathname: "/offerings/it-as-a-service",
                    state: { page: 3 }
                }}>IT as a Service</Link>
        </NavDropdown>
                  <NavDropdown.Divider />
                  <Link className="dropdown-item" eventKey="3" to='/careers'>Careers</Link>
                  <NavDropdown.Divider />
                  <Link className="dropdown-item" eventKey="4" href='/contact'>Contact</Link>
                </NavDropdown> */}
                 <MobileNav scrolled={scrolled} />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav" style={{display: 'flex', right: '50px', position : 'absolute'}} className='d-none d-sm-none d-md-none'>
                           <WebNav scrolled={scrolled} />
                        </Navbar.Collapse>
                        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                        {/* <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-md`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                            placement="end"
                            responsive='md'
                        >
                            <Offcanvas.Header closeButton closeVariant='white' className="pages-navbar-md">
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                    <Navbar.Brand href="/home" style={{width: "100%"}}>
                                        <img src="../assets/images/cqlogo.png" alt="CQ Technologies" className="brand-logo"/>    
                                    </Navbar.Brand>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1">
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'} href="/home">Home</Nav.Link>
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'} href="/offerings">Offerings</Nav.Link>
                                    <Nav.Link  style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'}href="/careers">Careers</Nav.Link>
                                    <Nav.Link style={{color : scrolled? '' : 'white'}}  className={scrolled ? 'nav-link scrolled' : 'nav-link'}href="/contact">Contact</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas> */}
                        {/* </Navbar.Collapse> */}
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default PagesHeader;